// data object for the currency filter

export default {
  data() {
    return {
      currencyDefault: {
        symbol: "₦", //currency symbol for naira
        thousandsSeparator: ",",
        fractionCount: 2,
        fractionSeparator: ".",
        symbolPosition: "front",
        symbolSpacing: false,
        avoidEmptyDecimals: undefined,
      },
      currencyNoSymbol: {
        symbol: "", //no currency symbol

        thousandsSeparator: ",",
        fractionCount: 2,
        fractionSeparator: ".",
        symbolPosition: "front",
        symbolSpacing: false,
        avoidEmptyDecimals: "",
      },
      currencyTestField: {
        symbol: "", //no currency symbol

        thousandsSeparator: ",",
        fractionCount: 2,
        fractionSeparator: ".",
        symbolPosition: "front",
        symbolSpacing: false,
        avoidEmptyDecimals: undefined,
      },
      currencyUSD: {
        symbol: "$", //currency symbol for US dollars
        thousandsSeparator: ",",
        fractionCount: 2,
        fractionSeparator: ".",
        symbolPosition: "front",
        symbolSpacing: false,
        avoidEmptyDecimals: undefined,
      },
    };
  },

  methods: {
    formatCurrency(value, currency = "NGN") {
      const currencyLang = {
        USD: "en-US",
        NGN: "en-NG",
        GBP: "en-GB",
        EUR: "en-EU",
      };

      const formatted = new Intl.NumberFormat(currencyLang[currency], {
        style: "currency",
        currency: currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(parseFloat(value));
      return formatted;
    },
  },
};
