<template>
  <div class="error-page">
    <div>
      <div class="">
        <span class="d-block text-center errorCode">404</span>
        <span class="d-block text-center errror-message">Form Not found</span>
      </div>
      <div class="d-flex justify-center align-center mt-6 mt-md-10">
        <v-card
          class="d-flex flex-row justify-center align-center info__card"
          elevation="0"
        >
          <v-avatar class="d-flex align-center ma-4" color="#F9EED2" size="40">
            <img src="@/assets/noforminfo.png" class="infoimg" alt="" />
          </v-avatar>
          <v-card-text class="info__text pl-0" style="background-color: #fff">
            Hello, for some reason I can’t find the requested form. Please
            confirm you have the right link or have been invited to view this
            form.
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@700&family=Sora:wght@700&display=swap");

/* * {
  background: #f2f2f2;
  margin: 0;
  padding: 0;
} */
.error-page {
  background-color: #f8f7f4;
  width: 100%;
  height: 100vh;
  justify-content: center;
  display: flex;
  align-items: center;
}

.infoimg {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.errorCode {
  font-family: "Sora", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 200px;
  line-height: 180px;
  /* identical to box height, or 90% */

  /* brown */

  color: #d7a47b;
}

.errror-message {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.73px;
  color: #19283d;
}

.info__card {
  max-width: 455px;
  background: #ffffff;
  border: 1px solid #fbf4e4;
  box-sizing: border-box;
  border-radius: 4px;
}
.info__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #596a73;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .errorCode {
    font-size: 150px;
  }
  .errror-message {
    font-size: 24px;
  }
  .info__text {
    font-size: 9px;
  }
  .info__card {
    max-width: 360px;
  }
}
</style>
