import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { VueFormBuilderPlugin } from "v-form-builder";
import "../src/assets/styles/formbuilder.css";
import "v-form-builder/dist/v-form-builder.css";
import VueMeta from "vue-meta";

//import VueSignature from "vue-signature-pad";
const VueSignature = require("vue-signature-pad");
import Notifications from "vue-notification";
import VueCurrencyFilter from 'vue-currency-filter'

Vue.config.productionTip = false;

Vue.use(VueSignature);
Vue.use(Notifications);
Vue.use(VueFormBuilderPlugin);
Vue.use(VueMeta);
Vue.use(VueCurrencyFilter)
Vue.use(require('vue-moment'));

new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount("#app");