<template>
  <div>
    <div
      class="paymentField d-flex flex-wrap align-center py-1 px-4 mt-2"
      :disabled="disabled"
    >
      <img
        :src="require('@/assets/Box_light.png')"
        style="width: 34px; height: 36px"
      />

      <p class="card-txt pt-4 pl-6">
        Pay on <span class="card-inner-text pl-2"> Delivery </span>
      </p>
      <v-spacer></v-spacer>
      <v-chip
        x-small
        text-color="#000000"
        color="#d7d7d7"
        class="py-1 float-end"
        v-if="isAvailable"
      >
        <span style="font: 10px">coming soon</span>
      </v-chip>
    </div>
    <div class="dropbox px-8 py-6" v-show="toggle">
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            outlined
            hide-details="auto"
            dense
            background-color="#ffffff"
            placeholder="Company Email"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            outlined
            hide-details="auto"
            dense
            background-color="#ffffff"
            placeholder="Phone Number"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <p class="hint pt-6">* to receive your invoice & receipt</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isAvailable: true,
      toggle: false,
    };
  },
  methods: {
    showDrop() {
      this.toggle = !this.toggle;
    },
  },
};
</script>

<style scoped>
.paymentField {
  width: 100%;
  background: #ffffff;
  opacity: 0.6;
  border: 1px solid rgba(25, 40, 61, 0.1);
  border-radius: 4px;
}
.card-txt {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  display: flex;
  align-items: center;
  color: #525e6e;
}
.card-inner-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  align-items: center;
  color: #031021;
}
.dropbox {
  width: 100%;
  /* height: 152px; */
  background: #ffffff;
  border: 1px solid rgba(25, 40, 61, 0.1);
  border-radius: 4px;
}

.v-text-field--outlined {
  border-color: rgba(25, 40, 61, 0.1) !important;
}

.hint {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 22px;
  color: #8f96a1;
}

[disabled] {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}
</style>
