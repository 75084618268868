<template>
  <div>
    <v-container style="height: 400px" v-if="isConfirming">
      <v-row class="fill-height" align-content="center" justify="center">
        <v-col class="text-subtitle-1 text-center" cols="12">
          Confirming Payment
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            color="primary"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
    </v-container>
    <div class="d-flex flex-column justify-center align-center" v-else>
      <img class="pngImg" src="@/assets/tick-circle.png" alt="good tick" />
      <p class="modal__title py-8">Payment Confirmed</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "paymentConfirmNotification",
data() {
    return {
      isConfirming: false,
    };
  },
  
  // on mount is confirming is false then show the tick image after 5 seconds
  mounted() {
    this.isConfirming = true;
    if (this.isConfirming) {
      setTimeout(() => {
        this.isConfirming = false;
      }, 5000);
    }
  },
};
</script>

<style lang="scss" scoped>
.pngImg {
  height: 130px;
  width: 130px;
}

.modal__title {
  font-family: "Inter";
  font-style: bold;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #19283d;
  max-width: 217px;
}
</style>
