<template>
  <v-dialog v-model="dialog" max-width="574">
    <v-card
      width=""
      flat
      class="ma-0"
      style="background: #f8f7f4; border-radius: 10px"
    >
      <v-card-title
        style="
          background: #ffffff;
          border-radius: 8px 8px 0px 0px;
          height: 54px;
        "
      >
        <img
          :src="require('@/assets/task-square.png')"
          width="20"
          height="20"
        />
        <span
          class="ml-5"
          style="
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #301f78;
          "
          >Sign</span
        >
        <v-spacer></v-spacer>
        <v-icon
          tag="button"
          @click="closeFormDialog"
          class="text-bolder"
          color="#596A73"
        >
          mdi-close
        </v-icon>
      </v-card-title>
      <template>
        <v-tabs
          slider-color="#19283d"
          slider-size="4"
          background-color="#F8f7f4"
          v-model="tab"
          align-with-title
          style="border-bottom: 1px solid #d9dee1"
        >
          <v-tabs-slider color="#19283d"></v-tabs-slider>

          <v-tab class="tab-title" style="color: #19283d"> signature </v-tab>
        </v-tabs>
      </template>
      <v-tabs-items v-model="tab" style="background-color: #fefcf8">
        <v-tab-item class="mt-13">
          <div class="d-flex align-center mx-10">
            <p class="pb-4" style="color: #121212">
              A signature is required to complete submitting this form. Draw
              your signature in the box below
            </p>
          </div>
          <!-- <div>
            <img :src="image" alt="" />
          </div> -->

          <div class="d-flex justify-center align-center">
            <template>
              <signature-pad v-model="image" ref="canvasImg" class="mx-10" />
            </template>
          </div>
          <template class="mt-6">
            <v-card-actions
              class="d-block d-sm-flex justify-end mt-2 mr-9 pa-md-0"
            >
              <v-btn
                v-if="image != null"
                @click="clearSignature"
                dark
                width="121"
                height="45"
                color="#F4F5F6"
                elevation="0"
                class="text-capitalize mt-md-8 mb-4 mb-md-16 ml-8 mr-md-2"
              >
                <v-icon small color="#19283D">mdi-eraser</v-icon>
                <span style="color: #19283d; font-weight: bold">clear</span>
              </v-btn>
              <v-btn
                link
                @click="saveSignature"
                dark
                height="45"
                color="#19283D"
                class="
                  text-capitalize
                  ml-8 ml-md-2
                  mt-md-8
                  mb-4 mb-md-16
                  px-md-4
                "
              >
                <v-icon class="">mdi-chevron-right</v-icon>
                <span class="">Submit Signature</span>
              </v-btn>
            </v-card-actions>
          </template>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
import signaturePad from "./signature-pad.vue";
// import dataURItoBlob from "../utilities/blobConverter";

export default {
  components: {
    signaturePad,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tab: 0,
      image: null,
      dialog: false,
    };
  },
  methods: {
    async saveSignature() {
      //send form entries and return the  variable entry_id

      // const formUrl = this.formData.formConfig.formActionURL;
      // var mimeString = this.image.split(",")[1];
      // const payload = {
      //   form: this.Data.id,
      //   hash: "erttus",
      //   organization: this.Data.data.organization.id,
      //   form_entry: this.formInputData,
      //   signature: mimeString,
      // };
      // try {
      //   let response = await axios({
      //     method: "POST",
      //     url: formUrl,
      //     data: payload,
      //   });

      //   console.log(response);
      //   this.isSummiting = false;

      //   if (response.status == 200) {
      //     this.signatureDialog = false;
      //     this.dialog = true;
      //   }
      // } catch (error) {
      //   console.error("There was an error!", error);
      // }

      // // console.log(JSON.stringify(this.image, null, 2));
      // var mimeString = this.image.split(",")[1];
      // console.log(mimeString);
      // const options = {
      //   Headers: {
      //     Accept: "application.json",
      //     "Content-Type": "multipart/form-data",
      //   },
      // };
      // //initialize the formData
      // const formData = new FormData();
      // // add the data needed for uploading the logo...
      // formData.append("files", mimeString);
      // formData.append("ref", "attachment.meta");
      // formData.append("refId", this.entry_id);
      // formData.append("field", "attachment");
      // //
      // console.log(formData);
      // // upload  logo data to the server

      // try {
      //   let response = await axios.post(
      //     `https://api.onpbot.com/v1/upload`,
      //     formData,
      //     options
      //   );

      //   console.log(response);
      // } catch (error) {
      //   console.error("There was an error!", error);
      // }

      this.$emit("input", this.getImagePayload);
      this.$emit("done");
      this.dialog = false;
    },

    clearSignature() {
      this.image = null;
      this.$refs.canvasImg.clearCanvas();
    },

    closeFormDialog() {
      this.show = false;
      this.signatureDialog = false;
    },
  },
  computed: {
    //get image
    getImagePayload() {
      //   var blob = dataURItoBlob(this.image);
      //   console.log(blob);

      //   return blob;
      return this.image;
    },

    //return full payload for image upload(signature)
    createImagePayload() {
      return {
        files: this.getImageFile,
        ref: "attachment.meta",
        refid: this.entry_id,
        field: "attachment",
      };
    },
  },
  watch: {
    show: {
      handler(val) {
        this.dialog = val;
      },
    },
    dialog(val) {
      if (!val) {
        this.clearSignature();
        this.$emit("close");
      }
    },
  },
};
</script>

<style scoped>
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

/* this styles are for  the signature pad */

.box {
  width: "100%";
  padding: 8px 16px;
}

.buttons {
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-top: 8px;
}

.tab-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: #19283d;
}

.logo-container {
  width: 250px;
}

@media only screen and (max-width: 600px) {
  .container-fluid {
    padding-right: 0%;
    padding-left: 0%;
  }

  .logo-container {
    width: 95%;
  }
  .full-width {
    width: 100%;
  }
}

.upper {
  top: 0px;
}
.lower {
  bottom: 0px;
}
</style>
