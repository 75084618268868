<template>
  <div class="form-sdk">
    <!-- <v-dialog
      v-model="actionModal"
      scrollable
      color="#F9FAFA"
      persistent
      width="100%"
      max-height="50vh"
      max-width="750px"
      :fullscreen="$vuetify.breakpoint.xs"
      transition="dialog-transition"
    >

    </v-dialog> -->

    <v-navigation-drawer
      :width="$vuetify.breakpoint.xs ? '100%' : '450px'"
      v-model="actionModal"
      right
      temporary
      stateless
      v-click-outside="outside"
      app
    >
      <div class="form-render">
        <div style="text-align: right">
          <v-btn
            v-if="$vuetify.breakpoint.xs"
            color="#FF0000"
            @click="closeSdk"
            icon
            ><v-icon size="20">mdi-close</v-icon></v-btn
          >
        </div>
        <div class="form-render__wrapper">
          <FormRender
            :isEmbedded="true"
            @pinSDkForm="pinDialog = $event"
            @openSDKForm="actionModal = true"
            @endSDK="endSDK"
            @formNotFound="formNotFound"
            @closeSDKForm="actionModal = false"
            :id="id"
          />
        </div>

        <div class="powered-by-hyphen">
          <a
            href="https://tryhyphen.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span> powered by </span>
            <img src="@/assets/newLogo.png" alt="powered by hyphen" />
          </a>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import FormRender from "../components/FormRender.vue";
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      actionModal: true,
      pinDialog: false,
    };
  },
  components: { FormRender },
  mounted() {
    // select htmlm, body, element and apply css overfloe: hidden
    document.querySelector("html").style.overflow = "hidden";
    document.querySelector("body").style.overflow = "hidden";
  },
  methods: {
    closeSdk() {
      window.parent.postMessage({ closeSDK: true }, "*");
    },
    endSDK() {
      this.closeSdk();
    },

    formNotFound() {
      setTimeout(() => {
        this.closeSdk();
      }, 5000);
    },

    outside(e) {
      //get click position
      var x = e.clientX;
      var y = e.clientY;
      //get element size and position in the page
      var element = document.querySelector(".v-navigation-drawer");
      var rect = element.getBoundingClientRect();
      // check if the click is in the element
      if (x > rect.left && x < rect.right && y > rect.top && y < rect.bottom) {
        // console.log("inside");
      } else {
        if (!this.pinDialog) {
          this.closeSdk();
        }
      }
    },
  },
};
</script>

<style lang="scss">
.theme--light,
body,
html,
.theme--light.v-application {
  background-color: transparent;
}

.form-sdk {
  height: 100vh;
  background: transparent;
  width: 100vw;
  z-index: 9999px;
  overflow: hidden;
}

.form-render {
  height: 100vh;
  width: 100%;
  z-index: 9999px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;

  &__wrapper {
    flex: 1;
    overflow: auto;
    padding: 10px;

    @media (min-width: 768px) {
      &::-webkit-scrollbar {
        width: 8px;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      }
      &::-webkit-scrollbar-thumb {
        background-color: var(--v-primary-base);
        border-radius: 10px;
      }
    }
  }

  .powered-by-hyphen {
    display: block;
    margin: 15px 25px;

    a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;

      @media (max-width: 500px) {
        display: flex;
      }
    }

    span {
      font-size: 14px;
      color: #8f96a1;
      margin-right: 5px;
      text-decoration: none;
    }

    img {
      height: 15px;
      margin-left: 5px;
      opacity: 0.8;
    }
  }

  a {
    display: block;
    margin: auto 40px;
  }
}
</style>
