<template>
  <div class="status-feedback">
    <div class="message" v-if="success">
      <img class="pngImg" src="@/assets/tick-circle.png" alt="good tick" />
      <h4>Payment Successful</h4>
    </div>
    <div class="message" v-else-if="failed">
      <h4>Payment Failed</h4>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      success: false,
      failed: false,
    };
  },
  mounted() {
    if (this.$route.query.success) {
      this.success = true;
    }

    if (this.$route.query.failed) {
      this.failed = true;
    }
  },
};
</script>
<style lang="scss" scoped>
.status-feedback {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .message {
    display: flex;
    flex-direction: column;
    align-items: center;
    h4 {
      margin-top: 20px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      color: #19283d;
    }
  }
}
</style>
