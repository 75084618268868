<template>
  <div class="form-page-view">
    <div class="form-page-view__wrapper">
      <div class="owner">
        <v-skeleton-loader
          :loading="skeletonLoading"
          type="image, card-heading@2, text@4"
          width="100%"
        >
          <div class="owner__wrapper">
            <div class="owner__wrapper__logo">
              <img :src="logo" :alt="formName" />
            </div>
            <div class="owner__wrapper__description">
              <h3>{{ organization }}</h3>

              <span>{{ formDescription }}</span>
            </div>
          </div>

          <div class="qrcode">
            <img :src="qrCode" :alt="formName" />
            <span
              >Scan this with your camera to complete this form on your mobile
              device</span
            >
          </div>
        </v-skeleton-loader>
      </div>
      <div class="content">
        <div class="content__wrapper">
          <FormRender
            :id="formId"
            @formDetails="setFormDetails"
            @isLoading="formLoading"
            @catchError="catchError"
          />
        </div>
        <div class="poweredBy">
          powered by
          <img class="pl-4" style="height: 16px" src="@/assets/newLogo.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormRender from "../components/FormRender.vue";
// import gsap from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  components: {
    FormRender,
  },
  metaInfo() {
    return {
      title: this.formName ? `${this.formName} - ${this.organization}` : "Form",
      description: this.formDescription || "Form Description",
      meta: [
        { charset: "utf-8" },

        {
          vmid: "description",
          hid: "description",
          name: "description",
          content: this.formDescription || "Form Description",
        },
      ],
      link: [
        {
          rel: "icon",
          type: "image/x-icon",
          href: this.logo,
        },
        {
          rel: "apple-touch-icon",
          type: "image/x-icon",
          href: this.logo,
        },
        {
          rel: "apple-touch-startup-image",
          type: "image/x-icon",
          href: this.logo,
        },
        {
          rel: "apple-touch-icon-precomposed",
          type: "image/x-icon",
          href: this.logo,
        },
      ],
    };
  },
  data() {
    return {
      // showOtherPayments: false,
      formId: "",
      skeletonLoading: false,
      logo: null,
      qrCode: null,

      showError: false,
      formDescription: null,

      formName: null,
      organization: null,

      Data: null,
      errMsg: null,
      sheetWidth: {
        xs: `100%`,
        md: "",
        sm: "320",
      },
      sheetHeight: {
        xs: "250",
        md: "165",
      },
    };
  },

  mounted() {
    this.formId = this.$route.params.id;
    // gsap.registerPlugin(ScrollTrigger);

    // let mm = gsap.matchMedia();

    // mm.add("(min-width: 850px)", () => {
    //   gsap.to(".content", {
    //     scrollTrigger: {
    //       trigger: ".content",
    //       pin: ".owner",
    //       start: "top top",
    //       end: "bottom bottom",
    //     },
    //   });
    // });
  },

  methods: {
    setFormDetails(data) {
      this.Data = data;
      this.formDescription = data.formDescription;
      this.logo = data.logo;
      this.qrCode = data.qrCode;
      this.formName = data.formName;
      this.organization = data.organization;
    },

    catchError(err) {
      this.errMsg = err.message;
      this.showError = err.state;
    },

    formLoading(loading) {
      this.skeletonLoading = loading;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-page-view {
  min-height: 100%;
  width: 100%;
  background-color: #f8f7f4;
  box-sizing: border-box;

  &__wrapper {
    width: 1100px;
    margin: auto;
    padding: 30px;
    gap: 25px;
    display: flex;

    @media only screen and (max-width: 768px) {
      width: 100%;
      flex-direction: column;
      padding: 50px 20px;
    }
    .owner {
      width: 30%;
      height: auto;
      position: -webkit-sticky; /* for Safari */
      position: sticky;
      top: 30px;
      align-self: flex-start; /* <-- this is the fix */

      @media only screen and (max-width: 768px) {
        width: 100%;
        position: unset;
      }

      &__wrapper {
        background: #ffffff;
        box-shadow: 0px 4px 16px rgba(204, 188, 252, 0.15);
        border-radius: 4px;

        &__logo {
          width: 100%;
          height: 200px;
          border-radius: inherit;

          :empty {
            background-color: #19283d;
          }

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: inherit;
          }
        }

        &__description {
          padding: 30px;

          //   span {
          //     opacity: 0.7;
          //   }

          h3 {
            font-weight: 700;
            font-size: 30px;
            padding: 0;
            margin-bottom: 10px;
          }

          border-radius: inherit;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          /* or 167% */

          color: #7f919b;

          @media only screen and (max-width: 768px) {
            padding: 12px;

            font-size: 16px;
            line-height: 24px;
          }

          opacity: 1;
          font-size: 18px;
          line-height: 32px;
          color: #455162;
        }
      }

      .qrcode {
        margin: auto;
        margin-top: 50px;
        display: none;

        width: 60%;
        text-align: center;
        img {
          display: block;
          width: 100%;
        }
      }
    }

    .content {
      width: 70%;

      @media only screen and (max-width: 768px) {
        width: 100%;
      }

      &__wrapper {
        padding: 35px;
        height: auto;
        background-color: #fff;
        box-shadow: 0px 4px 16px rgba(204, 188, 252, 0.15);
        border-radius: 4px;

        @media only screen and (max-width: 600px) {
          padding: 15px;
        }
      }

      .poweredBy {
        display: block;
        text-align: right;
        padding-top: 30px;
      }
    }
  }
}
</style>
