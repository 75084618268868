<template>
  <v-dialog
    v-model="dialog"
    overlay-color="#19283D"
    overlay-opacity="0.98"
    width="97%"
    max-width="574"
    :persistent="!isEmbedded"
  >
    <v-card
      width=""
      flat
      class="ma-0"
      style="background: #f8f7f4; border-radius: 10px"
    >
      <v-card-title
        style="
          background: #ffffff;
          border-radius: 8px 8px 0px 0px;
          height: 54px;
        "
      >
        <v-icon color="#19283D">mdi-lock</v-icon>
        <span
          class="ml-5"
          style="
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #301f78;
          "
          >Private Form
        </span>
        <v-spacer></v-spacer>
        <v-icon
          v-if="isEmbedded"
          tag="button"
          @click="closeFormDialog"
          class="text-bolder"
          color="#596A73"
        >
          mdi-close
        </v-icon>
      </v-card-title>
      <template>
        <v-tabs
          slider-color="#19283d"
          slider-size="4"
          background-color="#F8f7f4"
          v-model="tab"
          align-with-title
          style="border-bottom: 1px solid #d9dee1"
        >
          <v-tabs-slider color="#19283d"></v-tabs-slider>

          <v-tab class="tab-title" style="color: #19283d"> 2FA </v-tab>
        </v-tabs>
      </template>
      <v-tabs-items v-model="tab" style="background-color: #fefcf8">
        <v-tab-item class="pa-5 pa-md-10">
          <div class="d-flex align-center">
            <p style="color: #121212">
              <template v-if="step == 1">
                This form is private! access verification is required.
              </template>
              <template v-else>
                An email containing an OTP has been sent to <b>{{ email }}</b>
              </template>
            </p>
          </div>

          <div class="cover m-auto">
            <template v-if="step == 1">
              <v-text-field
                name="name"
                label="Email"
                v-model="email"
                :hint="!email ? 'required*' : null"
                hide-details="auto"
                outlined
                placeholder="Enter your business email"
              ></v-text-field>
              <div v-if="mode.demo && mode.user" class="demo">
                <span id="text">Demo use:</span>
                <v-chip @click="email = mode.user">{{ mode.user }}</v-chip>
              </div>
              <div style="text-align: right">
                <v-btn
                  link
                  dark
                  height="45"
                  color="#19283D"
                  class="text-capitalize"
                  @click="requestOtp"
                  :loading="isRequestingOTP"
                >
                  <span class=""
                    ><v-icon>mdi-chevron-right</v-icon> Proceed</span
                  >
                </v-btn>
              </div>
            </template>
            <template v-else>
              <v-text-field
                name="Otp"
                label="Otp"
                v-model="otp"
                hide-details="auto"
                outlined
                placeholder="Enter Otp"
              ></v-text-field>
              <div v-if="mode.demo && mode.user">
                <a
                  :href="`https://www.mailinator.com/v4/public/inboxes.jsp?to=${mode.user}`"
                  target="_blank"
                  ><v-chip>See OTP</v-chip></a
                >
              </div>
              <div style="text-align: right">
                <v-btn
                  link
                  dark
                  height="45"
                  color="#19283D"
                  class="text-capitalize"
                  :loading="isVerifyingOTP"
                  @click="verifyOTP"
                >
                  <span class=""><v-icon>mdi-chevron-right</v-icon>Verify</span>
                </v-btn>
              </div>
            </template>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    id: {
      default: null,
      required: true,
    },
    isEmbedded: {
      default: false,
    },

    mode: {
      type: Object,
      default: () => ({
        demo: false,
        user: "",
      }),
    },
  },
  data() {
    return {
      step: 1,
      tab: 0,
      dialog: false,
      email: null,
      otp: null,
      isRequestingOTP: false,
      isVerifyingOTP: false,
    };
  },
  methods: {
    closeFormDialog() {
      this.$emit("close");
    },

    async requestOtp() {
      if (!this.email) return;

      this.isRequestingOTP = true;

      try {
        await axios.post(process.env.VUE_APP_BASEURL + `forms/private`, {
          form: this.id,
          email: this.email,
        });
        this.step = 2;
      } catch (error) {
        this.$notify({
          group: "info",
          type: "error",
          title: "Error",
          text: error?.response?.data?.message || "Something went wrong!",
        });
      } finally {
        this.isRequestingOTP = false;
      }
    },

    async verifyOTP() {
      if (!this.otp) return;

      this.isVerifyingOTP = true;
      try {
        const response = await axios.get(
          process.env.VUE_APP_BASEURL +
            `organizations/form/${this.id}/?otp=${this.otp}`
        );
        this.$emit("verified", { response: response, email: this.email });
      } catch (error) {
        this.$notify({
          group: "info",
          type: "error",
          title: "Error",
          text: error?.response?.data?.message || "Something went wrong!",
        });
      } finally {
        this.isVerifyingOTP = false;
      }
    },
  },
  watch: {
    show: {
      immediate: true,
      handler(val) {
        this.dialog = val;

        if (!val) {
          this.email = "";
          this.otp = "";
        } else {
          this.email = this.$route.query.email;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.cover {
  background-color: #fff;
  padding: 25px;
  border: 1px solid #d9dee1;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 25px;

  .demo {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 3px;

    #text {
      white-space: nowrap;
      font-weight: 600;
      color: #19283d;
    }
  }
}

/* this styles are for  the signature pad */

.box {
  width: "100%";
  padding: 8px 16px;
}

.buttons {
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-top: 8px;
}

.tab-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: #19283d;
}

.logo-container {
  width: 250px;
}

@media only screen and (max-width: 600px) {
  .container-fluid {
    padding-right: 0%;
    padding-left: 0%;
  }

  .logo-container {
    width: 95%;
  }
  .full-width {
    width: 100%;
  }
}

.upper {
  top: 0px;
}
.lower {
  bottom: 0px;
}
</style>
