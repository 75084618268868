import Vue from "vue";
import VueRouter from "vue-router";
// import FormPageView from "../views/FormPageView.vue";
import newFormPageView from "../views/newFormPage.vue";
import previewSdk from "../views/previewSdk.vue";
import statusFeedback from "../views/statusFeedback.vue";
import home from "../views/home.vue";
import FormPageNotFound from "../components/FormPageNotFound.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: home,
  },
  {
    path: "/:id",
    name: "FormPageView",
    component: newFormPageView,
  },

  {
    name: "previewSdk",
    path: "/form/sdk/:id",
    component: previewSdk,
  },
  {
    name: "feedback",
    path: "/status/feedback",
    component: statusFeedback,
  },
  {
    name: "formNotFound",
    path: "*",
    component: FormPageNotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
