<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
    <notifications position="top right" group="info" />
  </v-app>
</template>

<script>
// <!-- Primary Meta Tags -->
// <title>Digital Interface Platform</title>
// <meta name="title" content="Digital Interface Platform" />
// <meta name="description" content="Digital interface" />

// <!-- Open Graph / Facebook -->
// <meta property="og:type" content="website" />
// <meta property="og:url" content="https://dev.hypn.so/hypnqaxkh7p2960" />
// <meta property="og:title" content="Digital Interface Platform" />
// <meta property="og:description" content="Digital interface" />
// <meta property="og:image" content="https://metatags.io/images/meta-tags.png" />

// <!-- Twitter -->
// <meta property="twitter:card" content="summary_large_image" />
// <meta property="twitter:url" content="https://dev.hypn.so/hypnqaxkh7p2960" />
// <meta property="twitter:title" content="Digital Interface Platform" />
// <meta property="twitter:description" content="Digital interface" />
// <meta property="twitter:image" content="https://metatags.io/images/meta-tags.png" />

// <!-- Meta Tags Generated with https://metatags.io -->
export default {
  name: "App",
  metaInfo: {
    title: "Digital Interface Platform",
    meta: [
      {
        name: "description",
        content: "Digital interface",
      },
      {
        property: "og:type",
        content: "website",
      },
      {
        property: "og:url",
        content: "https://dev.hypn.so",
      },
      {
        property: "og:title",
        content: "Digital Interface Platform",
      },
      {
        property: "og:description",
        content: "Digital interface",
      },
      {
        property: "og:image",
        content: "/dip.jpg",
      },
      {
        property: "twitter:card",
        content: "summary_large_image",
      },
      {
        property: "twitter:url",
        content: "https://dev.hypn.so",
      },
      {
        property: "twitter:title",
        content: "Digital Interface Platform",
      },
      {
        property: "twitter:description",
        content: "Digital interface",
      },
      {
        property: "twitter:image",
        content: "/dip.jpg",
      },
    ],
  },
  data: () => ({}),
};
</script>

<style></style>
