<template>
  <div class="px-md-0">
    <p class="section__info">
      Please make the following payment using your internet banking. You will
      receive a confirmation and a receipt in your email address
    </p>
    <hr style="border: 1px solid #d9dee1" />
    <p class="instruction">
      Please transfer {{ payableAmount | currency(currencyDefault) }} to
    </p>
    <div
      class="bankdetails__card px-4 px-md-10 d-flex flex-column justify-center"
    >
      <v-skeleton-loader
        :loading="accountnumber === ''"
        type="list-item-two-line"
      >
        <p class="bank__name mb-0">{{ bankname }}</p>
        <div class="d-flex align-center">
          <p class="d-flex mb-0 pr-6 bank__number">
            {{ accountnumber }}
          </p>
          <v-btn icon small @click="copyAccountnumber">
            <img
              :src="require('@/assets/Copy_light.png')"
              style="width: 20px; height: 20px"
            />
          </v-btn>
        </div>
      </v-skeleton-loader>
      <p class="details__info">Account Details</p>
    </div>
  </div>
</template>

<script>
import currencyFilter from "@/mixins/currencyFilter";
export default {
  name: "bankDetails",
  props: {
    bankname: {
      type: String,
    },
    accountnumber: {
      type: String,
    },
    payableAmount: {
      type: [Number, String],
    },
  },
  data() {
    return {
      // bank: {
      //   name: "providus bank",
      //   accountnumber: "0039039405",
      //   amount: 460000894,
      // },
    };
  },
  mixins: [currencyFilter],
  methods: {
    copyAccountnumber() {
      navigator.clipboard.writeText(this.accountnumber);
      this.$notify({
        group: "info",
        type: "success",
        title: "Copied",
        text: "Account number copied to clipboard",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.section__info {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: rgba(25, 40, 61, 0.8);
}

.bankdetails__card {
  width: 100%;
  height: 152px;
  background: #ffffff;
  border-radius: 8px;
  border: transparent;
  padding: 16px;
}

.bank__name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;

  /* bodyTxt_old */

  color: #596a73;
}
.bank__number {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */

  /* hyphen Blue */

  color: #19283d;
}

.details__info {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  /* bodyTxt_old */

  color: #596a73;
}

.instruction {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  /* identical to box height, or 178% */

  /* body text */

  color: rgba(25, 40, 61, 0.8);
}
</style>
