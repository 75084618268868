<template>
  <div class="select-payment" v-if="toBeBilled.length > 0">
    <div class="d-flex w-100 pb-3">
      <div class="w-50">
        <label>Item</label>
      </div>
      <div class="w-50"><label>Amount</label></div>
    </div>
    <div
      class="d-flex align-center gap-2 py-3"
      v-for="(payable, index) in payables"
      :key="index"
    >
      <v-select
        outlined
        placeholder="Choose Item"
        background-color="white"
        hide-details="auto"
        :items="toBeBilled"
        item-text="name"
        item-value="id"
        return-object
        v-model="payables[index]"
      ></v-select>
      <v-text-field
        outlined
        hide-details="auto"
        background-color="white"
        v-model="payables[index].amount"
        :disabled="
          (payables[index].id &&
            toBeBilled.find((bill) => bill.id === payable.id).c_disabled) ||
          payables[index].amount === undefined
        "
        @blur="formatAmount(index)"
      >
      </v-text-field>

      <v-btn @click="removeFieldRow(index)" small icon
        ><v-icon small color="red">mdi-close</v-icon></v-btn
      >
    </div>

    <p
      v-if="payables.length !== bills.length"
      class="addRow_txt d-inline-block pt-1 pl-2 m-0"
      @click="addNewFormRow"
    >
      + add new item
    </p>
  </div>
</template>

<script>
import currencyFilter from "@/mixins/currencyFilter";

export default {
  name: "selectPaymentFormFields",
  props: {
    bills: {
      type: Array,
      required: true,
      default: () => [],
    },

    isEmbedded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      payables: [{}],
      toBeBilled: [],
    };
  },

  mixins: [currencyFilter],

  watch: {
    payables: {
      handler: function (val) {
        // disable element that is already selected
        if (val) {
          this.toBeBilled = this.bills.map((bill) => {
            return {
              ...bill,
              amount: bill.amount
                ? this.addKobo(bill.amount).replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    ","
                  )
                : "",
              disabled:
                val.find((payable) => payable.id === bill.id) !== undefined,
              c_disabled: bill.amount !== "",
            };
          });

          // check every amount in the array is not empty
          const isNotEmpty = val.every((item) => {
            return item.amount !== "" && !isNaN(parseFloat(item.amount));
          });

          if (isNotEmpty) {
            this.$emit(
              "input",
              val.map((item) => {
                return {
                  ...item,
                  amount: item.amount.replace(/,/g, ""),

                  // delete c_disabled and disabled
                  c_disabled: undefined,
                  disabled: undefined,
                };
              })
            );
          } else {
            this.$emit("input", []);
          }
        }
      },
      deep: true,
    },
    bills: {
      handler: function (val) {
        if (val) {
          this.toBeBilled = val.map((bill) => {
            return {
              ...bill,
              amount: bill.amount
                ? this.addKobo(bill.amount).replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    ","
                  )
                : "",
              c_disabled: bill.amount !== "",
              disabled: false,
            };
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    //add a new row of select and text input field
    addNewFormRow() {
      this.payables.push({});
    },
    removeFieldRow(index) {
      if (this.payables.length > 1) {
        this.payables.splice(index, 1);
      } else {
        this.payables = [
          {
            amount: "",
          },
        ];
      }
    },

    formatAmount(index) {
      let amount = this.payables[index].amount;
      amount = amount.replace(/,/g, "");

      if (isNaN(parseFloat(amount))) {
        this.payables[index].amount = "";
        return;
      }

      if (amount) {
        amount = this.addKobo(amount).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.payables[index].amount = amount;
      }
    },

    addKobo(target) {
      let val = target;

      if (isNaN(parseFloat(target))) {
        return val;
      }

      // const regex = /\.\d{2}$/;

      // check if target ends with .
      if (target.endsWith(".")) {
        const newV = target + "00";
        val = newV.trim();
      } else if (target.indexOf(".") == -1) {
        const newV = target + ".00";
        val = newV.trim();
      } else {
        // already has dot
        const newV = parseFloat(target).toFixed(2);
        val = newV.trim();
      }

      // count number of dot in target
      const dotCount = (val.match(/\./g) || []).length;
      if (dotCount > 1) {
        // remove everything after the first dot
        val = val.slice(0, val.indexOf(".") + 1);
      }

      return val;
    },

    updateInput(value, index) {
      // console.log(value, index);

      this.payables[index] = {
        ...this.payables[index],
        amount: value,
      };
    },
  },
  computed: {
    filteredBills() {
      // remove bill that is already selected in payables array
      return this.bills.filter((bill) => {
        return (
          this.payables.find((payable) => {
            return payable.id === bill.id;
          }) === undefined
        );
      });
    },

    availableBills() {
      return [...this.bills];
    },
  },
};
</script>

<style scoped>
.select-payment {
  width: 100%;
  margin: 0 auto;
  padding: 0px 20px;
}
.form__wrap {
  background: #f8f7f4;
  border: 1px solid #f6f3ee;
  border-radius: 8px;
  padding: 20px 12px;
}

fieldset {
  border-collapse: collapse;
  border-color: red;
  border-style: solid;
  border-width: 1px;
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: -5px;
  transition-duration: 0.15s;
  transition-property: color;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
}

.addRow_txt {
  color: rgba(25, 40, 61, 0.8);
  cursor: pointer;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  margin-top: 10px;
  text-align: start;
  text-decoration: underline;
}

label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(25, 40, 61, 0.8);
}
.a-wrapper {
  background-color: #fff;
  padding: 20px 12px;
  border: 1px solid #d9dee1;
  border-radius: 4px;
}
</style>
