<template>
  <div>
    <div
      v-if="currentState < 1"
      class="paymentField d-flex flex-wrap align-center px-4 py-1 mt-2"
      @click.prevent="showDrop"
      :disabled="disabled"
    >
      <img
        :src="require('@/assets/Money_light.png')"
        style="width: 32px; height: 36px"
      />

      <p class="card-txt pt-4 pl-6">
        Pay with <span class="card-inner-text pl-2"> Bank Transfer </span>
      </p>
      <v-spacer></v-spacer>
      <v-chip
        x-small
        text-color="#8F96A1"
        color="#d7d7d7"
        class="py-1"
        v-if="isAvailable"
      >
        <span style="font: 10px">coming soon</span>
      </v-chip>
    </div>
    <transition name="fade" mode="out-in">
      <div class="dropbox px-4 py-6" v-if="transferForm" key="transferForm">
        <div class="transferForm__container">
          <div>
            <v-text-field
              outlined
              readonly
              hide-details="auto"
              dense
              background-color="#ffffff"
              v-model="invoicenumber"
              disabled
            >
            </v-text-field>
            <p class="hint">Issued order id or invoice number</p>
          </div>

          <v-text-field
            outlined
            readonly
            hide-details="auto"
            dense
            background-color="white"
            placeholder="youremail@gmail.com"
            v-model="email"
          >
          </v-text-field>
        </div>
      </div>

      <BankDetails
        v-if="currentState == 1 && showBankDetails"
        key="showBankDetails"
        :bankname="bankname"
        :accountnumber="accountnumber"
        :payableAmount="payableAmount"
      />

      <!-- confirmation notice/toast starts here -->

      <PaymentConfirmNotification
        v-if="currentState == 2"
        key="paymentSuccessful"
      />

      <!-- confirmation notice/toast ends here -->
    </transition>
    <div
      class="d-md-flex align-center px-md-0 py-8 pt-md-8"
      :class="justifyClass"
      v-if="toggle"
    >
      <v-btn
        outlined
        class="
          py-6
          mb-4 mb-md-0
          mr-md-3
          text-capitalize
          float-end float-md-none
        "
        v-if="currentState == 1"
      >
        <v-icon>mdi-chevron-right</v-icon>
        I will transfer later
      </v-btn>
      <v-btn
        :loading="isLoading"
        v-if="currentState <= 1 && showMe"
        @click.prevent="changeCurrentState"
        dark
        color="#19283d"
        class="py-6 ml-md-3 text-capitalize float-end"
        style="box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.24)"
      >
        <v-icon>mdi-chevron-right</v-icon>
        <span v-if="currentState == 1">I have transferred</span>
        <span v-if="currentState == 0">next</span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import BankDetails from "@/components/bankDetails.vue";
import PaymentConfirmNotification from "./paymentConfirmNotification.vue";
import axios from "axios";
export default {
  components: {
    BankDetails,
    PaymentConfirmNotification,
  },
  props: {
    invoicenumber: {
      type: String,
    },
    email: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    // bankname: {
    //   type: String,
    // },
    // accountnumber: {
    //   type: String,
    // },
    payableAmount: {
      type: [String, Number],
    },

    customerHypnId: {
      type: String,
    },
    organHypnId: {
      type: String,
    },
  },
  data() {
    return {
      isAvailable: false,
      toggle: false,
      isLoading: false,

      paymentSuccessful: false,
      showBankDetails: false,
      transferForm: false,
      steps: ["Form", "Payment", "Confirmation"],
      currentState: 0,
      showMe: false,
      bankname: "",
      accountnumber: "",

      // rules: {
      //   required: (value) => !!value || "Required.",
      //   email: (value) => /.+@.+\..+/.test(value) || "E-mail must be valid",
      //   phone: (value) =>
      //     /^(\+?234|0)[789]\d{9}$/.test(value) || "Phone number is invalid",
      // },
    };
  },

  computed: {
    // computed class to use justify-space-between or justify-end depending if current step is equal to 1
    justifyClass() {
      return this.currentState === 1 ? "justify-space-between" : "justify-end";
    },
  },
  methods: {
    showDrop() {
      this.toggle = !this.toggle;
      this.transferForm = !this.transferForm;
      this.showMe = !this.showMe;
      this.$emit("showDrop", this.toggle);
    },
    changeCurrentState() {
      if (this.currentState < this.steps.length - 2) {
        this.isLoading = true;
        this.currentState++;
        this.getCustomerBankDetails();
        this.isLoading = false;
        this.showBankDetails = true;
        this.transferForm = false;
        this.paymentSuccessful = false;
      } else if (this.currentState < this.steps.length - 1) {
        this.currentState++;

        this.paymentSuccessful = false;
        this.showBankDetails = true;
        this.transferForm = false;
      } else {
        this.currentState++;
        this.paymentSuccessful = true;
        this.showBankDetails = false;
        this.transferForm = false;
      }
    },

    // function to go back one step on the currentState
    goBack() {
      if (this.currentState > 0) {
        this.currentState--;
        this.showBankDetails = false;
        this.transferForm = true;
        this.paymentSuccessful = false;
      } else if (this.currentState == 0) {
        this.$emit("showDrop", false);
        this.toggle = !this.toggle;
        this.showMe = !this.showMe;
        this.showBankDetails = false;
        this.transferForm = false;
        this.paymentSuccessful = false;
      }
    },

    // get bank details
    async getCustomerBankDetails() {
      const payload = {
        customer: this.customerHypnId,
        organization: this.organHypnId,
      };

      try {
        this.isLoading = true;
        const response = await axios.post(
          process.env.VUE_APP_BASEURL + `wallets/${this.customerHypnId}`,
          payload
        );

        // console.log(response);
        this.bankname = response.data.data.bankname;
        this.accountnumber = response.data.data.accountnumber;
      } catch (error) {
        // console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#input-62 {
  font-size: 14px;
  font-weight: 600;
}

.paymentField {
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(25, 40, 61, 0.1);
  border-radius: 4px;
}

.card-txt {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  display: flex;
  align-items: center;
  color: #525e6e;
}

.card-inner-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  align-items: center;
  color: #031021;
}

.dropbox {
  width: 100%;
  /* height: 152px; */
  background: #fdfdfc;
  border: 1px solid rgba(25, 40, 61, 0.1);
  border-radius: 4px;
}

.v-text-field--outlined {
  border-color: rgba(25, 40, 61, 0.1) !important;
}

.hint {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 22px;
  color: #8f96a1;
}

/* style to flex 2 input fields */
.transferForm__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
}

/* transition styles for fade */
.v-fade-transition {
  transition: opacity 0.5s;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

[disabled] {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}
</style>
