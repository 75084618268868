<template>
  <v-container>
    <v-row class="d-flex justify-center align-center">
      <v-col cols="12" class="justify-center align-center">
        <p class="mx-auto title">
          powered by
          <a
            class="text-decoration-none"
            href="https://tryhyphen.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              width="200"
              style="image"
              class="pl-10"
              src="@/assets/newLogo.png"
          /></a>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "home",
};
</script>

<style scoped>
.image {
  height: 8rem;
  width: 8rem;
}

* {
  text-align: center;
}
.title {
  margin-top: 30vh;
  font-size: 3.5rem;
  font-weight: 700;
}
p {
  padding-bottom: 3vh;
}
</style>
