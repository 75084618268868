<template>
  <div>
    <canvas
      id="canvasElem"
      ref="canvas"
      width="500%"
      height="150%"
      @mousedown="mousedown"
      @mousemove="mousemove"
      @mouseup="sign = false"
      @mouseout="sign = false"
    />
    <!-- <input
      :src="signImage.src"
      type="file"
      style="display: none"
      @change="onFileChange"
    /> -->
  </div>
</template>

<script>
export default {
  name: "signature-pad",
  //
  data() {
    return {
      ctx: null,
      sign: false,
      prevX: 0,
      prevY: 0,
      signImage: null,
      signature: null,
    };
  },
  methods: {
    mousedown($event) {
      this.sign = true;
      this.prevX = $event.offsetX;
      this.prevY = $event.offsetY;
    },
    mousemove($event) {
      if (this.sign) {
        const currX = $event.offsetX;
        const currY = $event.offsetY;
        this.draw(this.prevX, this.prevY, currX, currY);
        this.prevX = currX;
        this.prevY = currY;
      }
    },
    async draw(depX, depY, destX, destY) {
      this.ctx.beginPath();
      this.ctx.moveTo(depX, depY);
      this.ctx.lineTo(destX, destY);
      this.ctx.closePath();
      this.ctx.stroke();

      this.signImage = this.$refs.canvas.toDataURL("image/png", 0.8);
    },
    clearCanvas() {
      this.ctx.clearRect(
        0,
        0,
        this.$refs.canvas.width,
        this.$refs.canvas.height
      );
    },
    // onFileChange(e) {
    //  this.signature = e.target.files[0];

    // console.log(this.signature);
    // },
  },
  watch: {
    signImage: function (val) {
      this.$emit("input", val);
    },
  },
  mounted() {
    this.ctx = this.$refs.canvas.getContext("2d");
    this.ctx.strokeStyle = "black";
    this.ctx.lineWidth = 2;
  },
};
</script>
<style scoped>
canvas {
  border: 1px solid #d9dee1;
  border-radius: 4px;
  background-color: white;
  cursor: crosshair;
  justify-self: center;
  align-self: center;
  width: 100%;
}
</style>
