<template>
  <div>
    <div
      class="paymentField d-flex flex-wrap align-center px-4 py-1 mt-2"
      @click="loadStripe"
      :disabled="disabled"
      :class="{ pulse: isLoading }"
    >
      <img
        :src="require('@/assets/Money_light.png')"
        style="width: 32px; height: 36px"
      />

      <p class="card-txt pt-4 pl-6">
        Pay with <span class="card-inner-text pl-2"> Stripe </span>
      </p>
      <v-spacer></v-spacer>
      <v-chip
        x-small
        text-color="#8F96A1"
        color="#d7d7d7"
        class="py-1"
        v-if="!isAvailable"
      >
        <span style="font: 10px">coming soon</span>
      </v-chip>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  components: {},
  props: {
    invoiceId: {
      type: String,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    customerHypnId: {
      type: String,
    },
    organHypnId: {
      type: String,
    },
  },
  data() {
    return {
      isAvailable: true,
      isLoading: false,
    };
  },
  methods: {
    async loadStripe() {
      if (this.isLoading) return;

      try {
        this.isLoading = true;
        const { data } = await axios.post(
          process.env.VUE_APP_BASEURL +
            `/transactions/stripe/new/${this.invoiceId}`
        );

        // create a link tag and click it to open the stripe checkout

        const link = document.createElement("a");
        link.href = data.url;
        link.target = "_blank";

        document.body.appendChild(link);
        link.click();

        // if (data.url) {
        //   window.open(data.url, "_blank");
        // }
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#input-62 {
  font-size: 14px;
  font-weight: 600;
}

.paymentField {
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(25, 40, 61, 0.1);
  border-radius: 4px;
}

.card-txt {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  display: flex;
  align-items: center;
  color: #525e6e;
}

.card-inner-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  align-items: center;
  color: #031021;
}

.dropbox {
  width: 100%;
  /* height: 152px; */
  background: #fdfdfc;
  border: 1px solid rgba(25, 40, 61, 0.1);
  border-radius: 4px;
}

.v-text-field--outlined {
  border-color: rgba(25, 40, 61, 0.1) !important;
}

.hint {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 22px;
  color: #8f96a1;
}

/* style to flex 2 input fields */
.transferForm__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
}

/* transition styles for fade */
.v-fade-transition {
  transition: opacity 0.5s;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

// pulsing and fading animation

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.pulse {
  cursor: none;
  animation: pulse 1s infinite;
}

[disabled] {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}
</style>
