<template>
  <FormSdk :id="$route.params.id" />
</template>

<script>
import FormSdk from "./FormSdk.vue";
export default {
  components: { FormSdk },
};
</script>

<style></style>
